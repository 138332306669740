








































import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { WorkPlatformService } from '@WorkPlatform/api';
import ScrollList from './scroll-list.vue';
import { H3List } from '@authine/thinking-ui';
import UserInformationPopup from './user-information-popup.vue';

@Component({
  name: 'mWorkPlatFormNoticesList',
  components: {
    ScrollList,
    H3List,
    listItemTodo: () => import('./list-item-todo.vue'),
    UserInformationPopup,
  },
})
export default class mWorkPlatFormNoticesList extends Vue {
  @Prop({ default: '' }) searchTxt!: any;

  @Prop({ default: '' }) startTime!: any;

  @Prop({ default: '' }) endTime!: any;

  dataChange = false;

  page: number = 1;

  hasLoadedAll: boolean = false;

  finished: boolean = false;

  loading: boolean = false;

  list: any[] = [];

  todoType: string = '1';

  total: number = 0;

  appLoading: boolean = false;

  flag: boolean = false;

  pageSize: number = 20;

  userId: string = '';

  @Watch('appLoading')
  showToast (show: boolean) {
    if (show) {
      this.$toast.show({
        icon: 'loading',
        text: '加载中',
      });
    } else {
      this.$toast.hide();
    }
  }

  // 切换todotype
  checkTodoType (param: string) {
    this.todoType = param;
    this.resetParams();
  }

  loadMore () {
    const curNum = this.page * this.pageSize;
    if (curNum < this.total) {
      this.nextPage();
    } else {
      this.hasLoadedAll = true;
    }
  }

  /**
   * 滚动加载
   * @val: 检测 下拉加载
   * @type: 检测 切换tab加载,展示loading
   */
  async nextPage (val?: string, type?: string) {
    // 控件问题，如果数据没撑满一页的时候会发起两次请求，所以拦截一下
    if (this.flag) {
      return;
    }
    this.flag = true;
    const wait: number = 100;
    const emptyCount: number = 0;
    const durationTime: number = 1000;
    setTimeout(() => {
      this.flag = false;
    }, wait);
    // end

    this.loading = true;
    if (type === 'checkTab') {
      this.appLoading = true;
    }

    const data = await this.getTodoList();
    const itemCount = await this.getWorkItemCount();
    this.page++;

    this.list.push(...data.data);
    this.finished = this.list.length >= (data.total || emptyCount);
    this.total = data.total || 0;
    this.loading = false;
    if (val === 'refresh') {
      this.$toast.show({
        text: '刷新成功',
        duration: durationTime,
      });
    }
    if (type === 'checkTab') {
      this.appLoading = false;
    }
    if (this.searchTxt === '') {
      this.$emit('todoTotal', itemCount.workItemCount);
      this.$emit('waitRead', itemCount.circulateItemCount || emptyCount);
    }
    this.appLoading = false;
  }

  async getWorkItemCount () {
    const res = await WorkPlatformService.getWorkItemCount();

    if (res.success) {
      return res.data;
    }
    return {};
  }

  /**
   * 重置接口参数
   */
  resetParams () {
    return new Promise((resolve: (value: unknown) => void) => {
      this.list = [];
      this.page = 1;
      this.finished = false;
      resolve(true);
    });
  }

  // 下拉刷新
  async onRefresh () {
    this.hasLoadedAll = false;
    this.appLoading = true;
    await this.resetParams();
    this.nextPage('refresh');
  }

  /**
   * 获取通知
   * @todoType: 通知类型
   */
  getTodoList () {
    const params: any = {
      todoType: this.todoType,
      page: this.page,
      size: 20,
      instanceName: this.searchTxt,
    };
    if (this.todoType === '3' || this.todoType === '4') {
      params.startTime = this.startTime;
      params.endTime = this.endTime;
    }
    return WorkPlatformService.getTodoList(params).then((res: any) => {
      if (res.success) {
        return res;
      } else {
        return {};
      }
    });
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  reloadPage (evt: any) {
    if (evt.data === 'reload' && evt.origin === window.location.origin) {
      this.onRefresh();
    }
  }

  /**
   * 展示人员信息
   */
  async showUserCard (id) {
    this.userId = id;
    this.$nextTick(() => {
      (this.$refs['user-card'] as any).togglePopupVisible();
    });
  }

  created () {
    this.onRefresh();
  }
}
