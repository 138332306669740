















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { H3PopupModal } from '@authine/thinking-ui';
import { WorkPlatformService } from '../../../api/work-platform';
import Avatar from './avatar.vue';

@Component({
  name: 'UserInformationPopup',
  components: {
    AH3PopupModal: H3PopupModal,
    Avatar,
  },
})
export default class UserInformationPopup extends Vue {
  @Prop() userId!: string;

  // 显示弹框
  showApproveInfo: boolean = false;

  userInfo: any = {};

  /**
   * 控制是否显示弹窗
   */
  async togglePopupVisible () {
    if (!this.showApproveInfo) {
      const res: any = await WorkPlatformService.getUserDetail(this.userId);
      if (res.success) {
        this.userInfo = res.data;
      }
    }
    this.showApproveInfo = !this.showApproveInfo;
  }

  getUserCardParentElement () {
    document.getElementById('app');
  }
}
