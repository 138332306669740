





















import { Component, Vue, Prop } from 'vue-property-decorator';

const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN') || '';
const protocolReg = /^http(s)?:\/\//;

@Component({
  name: 'Avatar',
})
export default class Avatar extends Vue {
  @Prop() imgUrl!: string;

  @Prop({ default: '' }) defaultName!: string;

  get src () {
    return protocolReg.test(this.imgUrl)
      ? this.imgUrl
      : `${ENV_ORIGIN}${this.imgUrl}`;
  }
}
